.scales {
  position: absolute;
  display: flex;
  top: 0;
  left: var(--grid-header-item-width);
  right: 0;
  height: var(--grid-container-height);
  background: #fff;
}

.scale {
  font-size: .8em;
  flex-basis: calc(100% / var(--grid-num-cols));
  height: 100%;
  z-index: 0;
  border-left: 1px dashed #ddd;
}
