
.root {
  position: relative;
  height: 100%;
}

.globalSpinner {
  position: fixed;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.2;
}

.innerSpinner {
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.2;
}

.body {
  z-index: 1;
  height: 100%;
}

.bodyLoading {
  opacity: 0.3;
}

