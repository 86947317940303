


/*
   ------------------------------------------------- */

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}

.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;

}

.react-grid-item img {
  pointer-events: none;
  user-select: none;
}

.react-grid-item.cssTransforms {
  transition-property: transform;
}

.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
  opacity: 0.7;
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  background: red;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  right: 0em;
  top: .5em;
  bottom: .5em;
  width: 1em;
  border-left: 2px solid green;
  cursor: ew-resize;

  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: .15em;
    width: 1px;
    content: "";
    border-left: 2px solid green;
  }
}

.react-resizable-hide > .react-resizable-handle {
  display: none;
}


.react-grid-item > .react-delete-handle {
  position: absolute;
  width: 32px;
  height: 32px;
  background: #fff;
  //box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);

  color: #000;
  left: .5em;
  top: 50%;
  transform: translate(0, -50%);
  border: 1px solid rgba(255, 0, 0, 0.6);
  border-radius: 50px 50px;
  background: rgba(255, 0, 0, 0.6);
  cursor: pointer;

  &:before {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='48' viewBox='0 96 960 960' width='48'%3E%3Cpath d='m249 849-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z'/%3E%3C/svg%3E");
    background-size: 50% 50%;
    background-repeat: no-repeat;
    background-position: center center;
    content: "";
  }
}


.popover {
  width: 420px;
  max-width: 80vw;
}
