
.root {
  padding-right: 1em;
  padding-top: .5em;
}

.members {

}

.memberNotFound {
  opacity: 0.5;
  //font-size: .8em;
}

.member {
  margin-bottom: 1em;
}

.member__name {
  font-weight: bold;
  width: 100%;
  font-size: 1.2em;
  border-bottom: 1px solid #ccc;
  padding-bottom: .2em;
  margin-bottom: .5em;
}

.member__schedule {
  font-size: .8em;
}

.iconNotice {
  color: orange;
  margin-left: .5em;
}

.heading {
  font-size: 1.2em;
  font-weight: bold;
  padding-bottom: .2em;
  margin-bottom: .5em;
  border-bottom: 1px solid #666;
}
