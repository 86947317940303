.root {
  width: 500px;
  position: relative;
  min-height: 50vh;
}


.paginationUl {
  //justify-content: center;

  button {
    //font-size: .9em;
  }
}

@media (max-width: 500px) {
  .root {
    width: 90vw;
  }
  //.paginationUl {
  //  button {
  //    font-size: .9em;
  //    min-width: 28px;
  //    height: 28px;
  //    margin: 0 1px;
  //  }
  //}
}

.log {
  display: flex;
  align-items: center;
}

.logType {
  font-size: .9em;
  font-weight: bold;
  flex: 0 0 4em;
  white-space: nowrap;
  text-align: center;
  padding-right: 1em;

  &[data-type-code='create'] {
    color: #33691E;
  }

  &[data-type-code='delete'] {
    color: #D50000;
  }

  &[data-type-code='update'] {
    color: #FFAB00;
  }
}

.ListItemRoot {
  margin: 0 !important;
  padding: 0 !important;
  display: block !important;
  border-bottom: 1px solid #eee !important;

}

.ListItemButtonRoot {
  margin: 0 !important;
  padding: .5em .5em !important;

}

.ListItemTextRoot {
  margin: 0 !important;
  padding: 0 !important;
}

.logDetail {
  flex: 1;
}

.logDetailCause {
  display: flex;
  font-size: .9em;
  color: #78909C;
  line-height: 1;
  margin-top: .4em;
}

.logDetailCauseDate {
  margin-right: .5em;
  margin-left: .2em;
}

.logDetailCauseOperator {

}

.event {
  //display: flex;
  //align-items: center;
  //border: 1px solid #eee;
  color: #37474F;
  background: #ECEFF1;
  border-radius: 4px 4px;
  padding: .3em .8em;
  display: inline-block;
}

.eventDate {
  margin-right: .5em;
  font-size: .9em;
  display: inline;
}

.eventAttendees {
  margin-right: .5em;
  font-size: .9em;
  display: inline-block;
}

.eventAttendee {
  margin-right: .5em;
  display: inline;
}

.eventSubject {
  display: inline;
  font-weight: bold;
  margin-right: .5em;
  color: #263238;
}

.eventFlagName {
  //border: 1px solid #999;
  //padding: .1em .5em;
  font-weight: bold;
  margin-right: .5em;
  display: inline;
  color: #263238;
}

.chevron {
  flex: 0 0 3em;
  display: flex;
  align-items: center;
  justify-content: center;
}

