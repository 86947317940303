
.eventTop {
  display: flex;
  margin-bottom: .2em;
}

.eventBottom {

}

.events {

}

.event {
  word-wrap: break-word;
  margin-bottom: .5em;
  padding-left: .5em;
  padding-top: .2em;
  padding-bottom: .2em;
  border-left: .5em solid #78909C;
  max-width: 20em;
}

.eventSubject {
  font-weight: bold;
  margin-right: .5em;
  max-width: 15em;
  line-height: 1.2;
}

.eventSubjectDateRange {
  font-weight: normal;
  display: inline-block;
  opacity: 0.7;
}

.eventDate {
  opacity: 0.5;
}

.noEvents {
  opacity: 0.5;
}


.eventUsers {
  display: flex;
  max-width: 320px;
  flex-wrap: wrap;
  line-height: 1.1;
  opacity: 0.5;
  font-size: .9em;
  padding-right: .5em;
}

.eventUser {
  margin-right: .2em;
}
