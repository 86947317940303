.root {
  font-size: 16px;
  @media (min-width: 1536px) {
    font-size: 1.1vw;
  }
  color: #444;
}

.container {
  display: grid;
  grid-gap: 2px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media (max-width: 1536px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}

.user {
  border: 1px solid #ccc;
  height: 100%;
  padding: .5em .5em;
  display: flex;
  align-items: flex-start;
  //box-shadow: 0 0 1px rgba(0, 0, 0, 1);
  border-left-width: .3em;
}

.userContainer {
  display: flex;
  align-items: flex-start;
}

.userContent {

}

.userDisplayName {
  font-weight: bold;
  min-width: 4em;
  white-space: nowrap;
  line-height: 1.5em;
  color: #000;

  span {
    font-size: 1.2em;
  }
}


.userFlags {
  display: flex;
}

.userFlag {
  font-size: 1em;
  line-height: 1.5em;
  padding-right: .5em;
  font-weight: bold;
  white-space: nowrap;
}

.userEvents {
  line-height: 1.2;
}

.userFlags + .userEvents {
  margin-top: .5em;
}

.userEvent {
  font-size: .8em;
  line-height: 1.2;
  display: flex;
  margin-bottom: .3em;

  &:last-child {
    margin-bottom: 0;
  }
}


.userEventSubject {
  padding-right: .5em;
}

.userEventDateRange {
  display: flex;
  flex-wrap: wrap;
  color: #aaa;
  max-width: 8em;
}

.userEventStartDate {
  white-space: nowrap;
}

.userEventDateSep {
  margin: 0 .2em;
}

.userEventEndDate {
  white-space: nowrap;
}
