.root {
  height: 100%;
  font-size: 1.2vw;
  display: flex;
  flex-direction: column;
}

.nav {
  flex: 0 0 3em;
  display: flex;
  justify-content: center;
  align-items: center;

  & > * {
    padding: 0 1em;
    font-size: 1.4em;
    font-weight: bold;
  }

  nav {
    height: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2em;
  }
}

.body {
  flex: 1;
  display: flex;
  height: 100%;
}

.gridPane {
  flex: 1;
  width: 100%;
  padding: 1em;
  height: 100%;
  //background: blue;
}

.membersPane {
  flex: 0 0 15em;
  width: 100%;
  padding: 1em;
}


