.root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 65px;
  z-index: 1101;
  background: #f00;
  text-align: center;
  line-height: 60px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  label {
    display: block;
    font-weight: bold;
    font-size: 1.2em;
  }

  select {
    //appearance: none;
    //border: 1px solid #000;
    margin-left: 1em;
    font-size: 16px;
    padding: .5em;
    outline: none;
    margin-right: 1em;
  }


}

.blink {
  svg {
    animation: blinking 0.6s ease-in-out infinite alternate;
  }
}

.selectedValue {
  font-size: 1.2em;
  font-weight: bold;
  margin-right: 1em;
}

@media (max-width: 640px) {
  .selectedValue {
    font-size: .9em;
  }
}

@keyframes blinking {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
