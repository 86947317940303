$rowHeaderWidth: 10em;
$colHeaderHeight: 2em;
.root {
  width: 100%;
  height: 100%;
  outline: none;
  user-select: none;
  position: relative;
}

.background {
  position: absolute;
  padding-left: var(--grid-header-item-width);
  width: 100%;
  padding-top: var(--grid-scale-height);
}

.rowHeaders {
  position: absolute;
  top: 0;
  font-size: 1em;
  font-weight: bold;
  bottom: 0;
  left: 0;
  padding-top: var(--grid-scale-height);
  width: var(--grid-header-item-width);
}

.rowHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1em;
  height: var(--grid-header-item-height);
}

.toolbar {
  margin: .5em 0em;
}

.templateName {
  font-size: 24px;
  font-weight: bold;
}

.grid {
  //background: #ccc;
  width: 100%;
}

.frameItem {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: rgba(#000, 1);
  font-weight: bold;

  span {
    position: absolute;
    top: .2em;

  }

  &:before {
    position: absolute;
    content: "";
    top: 2px;
    bottom: 2px;
    left: 2px;
    right: 2px;
    background: rgba(#000, 0.1);
    z-index: 10;
  }

  div {
    opacity: 0.2;
    font-size: .8em;
  }
}

.item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2em;

  &:before {
    position: absolute;
    content: "";
    top: .1em;
    bottom: .1em;
    left: .3em;
    right: .3em;
    background: rgba(#fff, 1);
    z-index: -1;
  }

  &[data-collision='1']:before {
    top: .1em;
    bottom: .1em;
    //left: .1em;
    //right: .1em;
  }

  svg {
    position: absolute;
    left: 1em;
    margin-right: .5em;
    font-size: 1.2em;
    color: green;
  }


}


.debug {
  position: absolute;
  top: 0;
  left: 0;
}
