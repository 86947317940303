$borderColor: #b0bec5;
$strongBorderColor: #37474F;
$headerBackgroundColor: #ECEFF1;

.root {
  //overflow: scroll;
  //width: 100%;
  color: #263238;
}

.headerContent {
  width: 100vw;
  background: #fff;

  &[data-in-view='0'] {
    z-index: 10;
    position: fixed;
    top: var(--layout-appbar-height);
    left: 0;
  }
}

.tabContainer {
  //position: sticky;
  //top: 60px;
  //left: 0;
  //display: inline-flex;
  //align-items: center;
  width: 100vw;
}

.table {
  table-layout: fixed;
  //overflow: scroll;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  border-collapse: separate;
  border-spacing: 0;
  //font-size: .55vw;
  font-size: 1em;
  margin: auto;
  margin-left: 0;
  user-select: none;
  transition: all .3s;

  @media (max-width: 640px) {
    font-size: .8em;
  }

  &[data-uploading='1'] {
    opacity: 0.4;
    pointer-events: none;
  }

  caption {
    text-align: left;
  }

  thead {
    position: sticky;
    //top: 100px;
    z-index: 10;
  }

  //th {
  //  text-align: center;
  //}
  //
  //th span {
  //  display: inline-flex;
  //  padding: 1em 0;
  //  align-items: center;
  //}
  //
  //th:nth-child(1) {
  //  height: 6em;
  //}


  //th:nth-child(2) span {
  //  display: block;
  //  writing-mode: inherit;
  //  -ms-writing-mode: inherit;
  //}


  //td {
  //  cursor: pointer;
  //}

  td[data-clickable='1']:hover:after {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(#673ab7, 0.1);
  }

  td[data-active='1']:after {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(#673ab7, 0.5);
  }

  //td:nth-child(1),
  //td:nth-child(2) {
  //  cursor: default;
  //}
  //
  //td:nth-child(1):after,
  //td:nth-child(2):after {
  //  display: none;
  //}

  //th:nth-child(1) {
  //position: -webkit-sticky;
  //position: sticky;
  //left: 0;
  //top: 60px;
  //z-index: 3;
  //}


  // 日付
  //td:nth-child(1) {
  //
  //}


}

.headerDummyCell {
  border-top: 1px solid $borderColor;
  border-bottom: 1px solid $strongBorderColor;
  border-right: 1px solid $borderColor;
  background: $headerBackgroundColor;
  height: 6em;
}

.headerCell {
  border-top: 1px solid $borderColor;
  border-bottom: 1px solid $strongBorderColor;
  border-right: 1px solid $borderColor;
  background: $headerBackgroundColor;
}

.dayName {
  font-weight: bold;
  background: $headerBackgroundColor;
  border-bottom: 1px solid $strongBorderColor;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 3;
  border-right: 1px solid $strongBorderColor;

  span {
    width: 4em;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: 3.5em;
  }

  &[data-week-number='6'] {
    border-bottom: 2px solid #ff8a80;
  }

  &[data-week-number='6'],
  &[data-week-number='0'] {
    background: #ffebee;
  }
}

.rokuyoName {
  width: 3em;
  border-bottom: 1px solid $strongBorderColor;
  border-right: 1px solid $strongBorderColor;
  background: $headerBackgroundColor;
  // 六曜
  & span {
    font-weight: bold;
    display: block;
    width: 3em;
    text-align: center;
  }

  &[data-rokuyo='大安'] {
    color: #f44336;
  }
}

.verticalText {
  width: 3.5em !important;

  span {
    white-space: pre;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-lr;
  }
}
