.eventList {
  border-spacing: 0;
  table-layout: fixed;
  border-collapse: collapse;
  margin-bottom: 1em;

  & td {
    //border: 1px solid #eee;
    padding: .2em .2em;
    border-bottom: 1px solid #ccc;
  }

}

.eventListItem {
}

.eventListItem__subject {
  font-weight: bold;
  line-height: 1.2;
  word-break: break-word;
  padding-right: 1em !important;
}

.eventListItem__startDate {
  white-space: nowrap;
  opacity: 0.5;
}

.eventListItem__endDate {
  white-space: nowrap;
  opacity: 0.5;
}

.eventListItem__dateSep {
  white-space: nowrap;
  opacity: 0.5;

}

.eventListItem__edit {
}

.eventListItem__delete {

}

.loadingOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1;
}
