.root {
  padding: 5em 0;
  user-select: none;
}


.title {
  font-weight: bold;
  text-align: center;
  opacity: 0.3;
  font-size: 24px;
  margin-bottom: 2em;
}

.header {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  opacity: 0.3;
}

.headerIcon {

}

.headerContent {

}
