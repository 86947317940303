.root {
  --layout-header-height: 64px;
}

.actions {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--layout-header-height);
  background: #f00;
  color: #fff;
  transition: all 0.5s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(0);
  z-index: 1101;

  button {
    font-size: 16px;
    margin-right: 1.5em;
  }

  button:last-child {
    margin-left: 0;
  }

  label {
    font-weight: bold;
    letter-spacing: .1em;
    font-size: 1.2em;
    margin-right: 2em;
  }
}

.backdrop {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  height: 10px;
  background-color: red;
  pointer-events: none;
  transition: all .5s;
  z-index: 1101;
  opacity: 1;

  &:before {
    content: "";
    position: fixed;
    bottom: 0;
    right: 0;
    top: 0;
    width: 10px;
    background-color: red;
  }

  &:after {
    content: "";
    position: fixed;
    bottom: 0;
    left: 0;
    top: 0;
    width: 10px;
    background-color: red;
  }
}


.root[data-show="0"] .actions {
  transform: translateY(-110%);
}

.root[data-show="0"] .backdrop {
  opacity: 0;
}
