$borderColor: #b0bec5;

.cell {
  border-right: 1px solid $borderColor;
  border-bottom: 1px solid $borderColor;
  padding: 0;
  position: relative;
  background: #fff;
  vertical-align: top;

}

.events {
  padding: .5em;
  font-size: .8em;
  width: 19em;
}

.event {
  margin-bottom: .4em;
  white-space: normal;
  word-wrap: break-word;
  line-height: 1.2;

  &:last-child {
    margin-bottom: 0;
  }
}


.subject {
  font-weight: bold;
  margin-right: .5em;
}

.range {
  display: inline-block;
  opacity: 0.7;
}

