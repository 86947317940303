.header {
  //width: 100vw;
  //overflow: hidden;
  //overflow: hidden;
  text-align: center;
}

.content {
  position: sticky;
  left: 0;
  //justify-content: flex-start;
  display: inline-flex;
  align-items: center;
  font-size: 1.7vw;
  padding: .5em 1em;
}

.today {
  white-space: nowrap;
}

.navigation {
  display: flex;
  align-items: center;
  height: 100%;
}

.name {
  display: flex;
  align-items: center;
  margin-right: 1em;
  font-weight: bold;
}

.date {
  display: flex;
  align-items: center;
  margin-right: 1em;
  font-weight: bold;
  white-space: nowrap;
  line-height: 1em;
  cursor: pointer;
}

.yesterday, .today, .tomorrow {
  //margin-right: .1em;

  a {
    font-size: 1em;
    line-height: 1;
  }

  svg {
    font-size: 1em;
  }

}

.dummy {
  flex: 0 0 1em;
}

@media (max-width: 640px) {
  .content {
    font-size: 18px;
    //justify-content: flex-start;
  }
}
