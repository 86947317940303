$borderColor: #b0bec5;

.userCell {
  border-right: 1px solid $borderColor;
  border-bottom: 1px solid $borderColor;
  padding: 0;
  position: relative;
  background: #fff;
}

.labels {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 3.5em;
  min-height: 3.5em;
  padding: .3em;
}

.label {
  width: 100%;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: .7em;
  line-height: 1;

  &[data-strlen='1'] {
    font-size: 1.1em;
  }

  &[data-strlen='2'] {
    font-size: .8em;
  }

  &[data-strlen='3'] {
    font-size: .7em;
  }
}

.count {
  display: inline-block;
  font-size: 1.1em;
  margin: 0 .1em;
}

